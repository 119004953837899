.container {
  margin-top: 0px;
  margin-bottom: 20px;
  text-align: center;

  .gameName {
    font-weight: 600;
    font-size: 15px;
    line-height: 130%;
    margin-top: 10px;
    text-transform: uppercase;
  }

  .logo {
    width: 200px;
  }
}
