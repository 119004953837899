:root {
  --c-body: #0c0513;
  --c-background-dark: #3c135f;
  --c-background-light: #aa1e45;
  --c-background: #f2f2f2;
  --c-background-score: #efe1ad;
  --c-background-secondary: rgba(0, 0, 0, 0.5);
  --c-background-primary: rgba(0, 0, 0, 0.1);
  --c-background-accent: linear-gradient(
    90deg,
    rgba(0, 15, 116, 0.5) 0%,
    rgba(232, 26, 30, 0.5) 100%
  );
  --c-background-hover: rgba(255, 214, 51, 0.32);
  --c-background-card: #ffffff;
  --c-background-highlighted: rgba(255, 214, 51, 0.32);

  --c-button-primary: rgba(255, 214, 51, 1);
  --c-button-primary-active: #f7c600;
  --c-button-primary-hover: #ffe065;
  --c-button-primary-disabled: #e6ad00;

  --c-text-primary: #151515;
  --c-text-primary-alt: #ffffff;
}
