:root {
  --control-paddings: 8px 16px;

  --header-height: 94px;

  --control-height-s: 37px;
  --control-height-m: 48px;
  /* --control-height-l: 60px; */

  --transition: all ease-in-out 150ms;

  --border-radius: 5px;
}
